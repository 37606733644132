import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

export const query = graphql`
	query RisksQuery($Slug: String!) {
		strapiRisk(Slug: { eq: $Slug }) {
			PageBuilder
			Paragraph
			Slug
			Title
			HeroImage {
				provider_metadata {
					public_id
				}
				formats {
					medium {
						url
					}
					small {
						url
					}
					thumbnail {
						url
					}
				}
			}
		}
	}
`

const Risk = ({ data }) => {
	const pageData = data.strapiRisk
	return (
		<Layout>
			<Helmet>
				<title>Delta Underwriting | Risks | {pageData.Title}</title>
				<meta name="description" content={pageData.Paragraph} />
			</Helmet>
			<PageIntro
				title={pageData.Title}
				summary={pageData.Paragraph}
				image={pageData.HeroImage}
			/>
			<h1 class="visually-hidden">{pageData.Title}</h1>
			<PageBuilder data={pageData.PageBuilder} />
		</Layout>
	)
}

export default Risk
